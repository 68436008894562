import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from 'gatsby-plugin-image';

import { FaPlay } from '@react-icons/all-files/fa/FaPlay';
import { GDPRCoOv, GDPRCoOvConfig, IframeSettings } from 'gdpr-c-o';

import { RootState } from './state/store';
import { loadYoutubeIFrameApi, startVideo } from './state/youtube-slice';

export interface OwnProps {
  id: number;
  video: string;
  thumbnail: ImageDataLike;
  lang?: 'de' | 'en';
  title?: string;
}

const mapStateToProps = (state: RootState, _ownProps: OwnProps) => ({
  youtubeApiLoading: state.youtube.youtubeApiLoading,
  youtubeApiLoaded: state.youtube.youtubeApiLoaded,
  idCurrentlyPlaying: state.youtube.idCurrentlyPlaying,
  showVideos: state.youtube.showVideos,
});

const mapDispatchToProps = {
  loadYoutubeIFrameApi,
  startVideo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
type Props = OwnProps & ReduxProps;

const SharedUiYoutube = ({
  id,
  video,
  showVideos,
  thumbnail,
  youtubeApiLoaded,
  idCurrentlyPlaying,
  youtubeApiLoading,
  loadYoutubeIFrameApi,
  startVideo,
  lang = 'de',
  title,
}: Props): JSX.Element => {
  const { t, i18n } = useTranslation();

  const [ytPlayer, setYtPlayer] = useState<YT.Player>();
  const [iFrameLoaded, setIFrameLoaded] = useState<boolean>(false);
  const [gdprIframe, setGdprIframe] = useState<GDPRCoOv>();
  const [playerState, setPlayerState] = useState<YT.PlayerState>(-1);
  const [enableCookie, setEnableCookie] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(navigator.language || lang);
  }, [lang, i18n]);

  useEffect(() => {
    const iframeId = `iframe-${video}`;

    const checkIframePresence = (
      _mutationsList: MutationRecord[],
      _observer: MutationObserver,
    ) => {
      // Check if the iframe div is in the DOM
      const iFrameDiv = document.getElementById(iframeId);
      setIFrameLoaded(!!iFrameDiv);
    };

    const observer = new MutationObserver(checkIframePresence);

    observer.observe(document.body, {
      childList: true,
      subtree: true, // Watch for changes in child elements as well
    });

    return () => {
      observer.disconnect();
    };
  }, [video]);

  useEffect(() => {
    // listen to play/pause/... events
    const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
      setPlayerState(event?.data);
      if (event?.data === YT.PlayerState.PLAYING) {
        startVideo(id);
      }
    };

    // as soon as youtube iFrame api script is loaded, we can use it
    if (youtubeApiLoaded && iFrameLoaded) {
      const player = new window.YT.Player(`iframe-${video}`);
      player.addEventListener('onStateChange', onPlayerStateChange);
      setYtPlayer(player);
    }

    return () => {
      if (ytPlayer) {
        ytPlayer.destroy();
      }
    };
  }, [youtubeApiLoaded, iFrameLoaded]);

  // create iFrame with GDPR overlay
  useEffect(() => {
    if (!gdprIframe) {
      const iframeSettings = new IframeSettings(
        '100%',
        '100%',
        '0',
        `https://www.youtube-nocookie.com/embed/${video}?enablejsapi=1`,
        `iframe-${video}`,
      );
      const gdprConfig = new GDPRCoOvConfig(
        iframeWrapperId,
        overlayId,
        30,
        iframeSettings,
      );
      const tmpIFrameInstance = new GDPRCoOv(gdprConfig);
      tmpIFrameInstance.init();
      setGdprIframe(tmpIFrameInstance);
    }

    if (showVideos.show && !gdprIframe?.isAlreadyAccepted()) {
      // cookie setting will be set by one component only
      gdprIframe?.userAccepted(showVideos.saveCookies);
    } else if (
      gdprIframe?.isAlreadyAccepted() &&
      !youtubeApiLoaded &&
      !youtubeApiLoading
    ) {
      loadYoutubeIFrameApi(enableCookie);
    }
  }, [showVideos, gdprIframe]);

  // stop video if another video is running
  useEffect(() => {
    if (idCurrentlyPlaying !== id && playerState === 1) {
      ytPlayer?.pauseVideo();
    }
  }, [idCurrentlyPlaying]);

  const iframeWrapperId = `iframe-wrapper-${video}`;
  const overlayId = `overlay-${video}`;

  const loadYoutubeApi = () => {
    loadYoutubeIFrameApi(enableCookie);
  };

  const acceptIframeCheckedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEnableCookie(event.target.checked);
  };

  return (
    <div>
      <div id={iframeWrapperId} className="aspect-video">
        <div
          id={overlayId}
          className="relative flex h-full w-full overflow-hidden text-center"
        >
          <GatsbyImage
            image={getImage(thumbnail) as IGatsbyImageData}
            alt="Youtube Thumbnail"
            className="!absolute inset-0"
            objectFit="cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-[#000] to-transparent max-sm:bg-black/60" />
          <div className="z-0 flex flex-col items-center justify-center p-5 text-xs text-white sm:justify-end md:text-base">
            <p>
              {t('infoText1')} &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/t/terms"
              >
                {t('infoLinkText')}
              </a>
              &nbsp; {t('infoText2')}
            </p>

            <button
              className="sm:my-10self-center bg-retroBlue hover:ring-light-bg my-4 rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white hover:ring-1"
              type="button"
              onClick={loadYoutubeApi}
            >
              <span className="flex items-center gap-2">
                <FaPlay /> {t('buttonText')}
              </span>
            </button>
            <label className="cursor-pointer">
              <input
                className="cursor-pointer"
                type="checkbox"
                onChange={acceptIframeCheckedHandler}
              />
              &nbsp;{t('alwaysLoadHint')}
            </label>
          </div>
        </div>
      </div>
      {title && <h4 className="mt-2 w-full text-center">{title}</h4>}
    </div>
  );
};

const ConnectedSharedUiYoutube = connector(SharedUiYoutube);

export { ConnectedSharedUiYoutube };
